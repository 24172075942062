import React, { useState, useEffect } from 'react';
import { Table, Button, Breadcrumb, Modal } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import ChatListModal from '../modals/ChatListModal';
import moment from 'moment';
import './index.css';
import SavedChatsModal from '../modals/SavedChatsModal';

function AccountList() {
  const [accounts, setAccounts] = useState([]);
  const [isChatListVisible, setChatListVisible] = useState(false);
  const [isSavedChatsVisible, setSavedChatsVisible] = useState(false);
  const [chatsToShow, setChatsToShow] = useState([]);
  const [accountToDelete, setAccountToDelete] = useState(null);
  const [accountId, setAccountId] = useState(null);
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] = useState(false);
  const [savedChats, setSavedChats] = useState([]); // State to store saved chats

  const columns = [
    {
      title: 'Имя',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Дата добавления',
      dataIndex: 'created',
      key: 'created',
      render: (text) => moment(text).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: 'Действия',
      key: 'actions',
      render: (text, record) => (
        <span>
          <Button onClick={() => handleViewChats(record)}>Список чатов</Button>
          <Button onClick={() => showDeleteConfirmation(record)}>Удалить</Button>
        </span>
      ),
    },
  ];

  useEffect(() => {
    const fetchAccounts = async () => {
      try {
        const response = await fetch('/api/telegram/accounts/');
        const data = await response.json();
        setAccounts(data);
      } catch (error) {
        console.error('Ошибка при загрузке данных:', error);
      }
    };

    fetchAccounts();
  }, []);

  const handleViewChats = (account) => {
    const chats = account.chats || [];
    setAccountId(account.id)
    setChatListVisible(true);
    setChatsToShow(chats);
  };

  const showDeleteConfirmation = (account) => {
    setAccountToDelete(account);
    setDeleteConfirmationVisible(true);
  };

  const handleDeleteAccount = async () => {
    try {
      await fetch(`/api/telegram/accounts/${accountToDelete.id}`, {
        method: 'DELETE',
      });

      setAccounts((prevAccounts) =>
        prevAccounts.filter((acc) => acc.id !== accountToDelete.id)
      );

      console.log('Удаление аккаунта успешно выполнено:', accountToDelete);
    } catch (error) {
      console.error('Ошибка при удалении аккаунта:', error);
    } finally {
      setAccountToDelete(null);
      setDeleteConfirmationVisible(false);
    }
  };

  const handleCancel = () => {
    setAccountToDelete(null);
    setChatListVisible(false);
    setSavedChatsVisible(false);
    setDeleteConfirmationVisible(false);
  };

  const handleSavedChats = async () => {
    try {
      const response = await fetch('/api/telegram/saved_chats/');
      const data = await response.json();
      setSavedChats(data);
      setSavedChatsVisible(true);
    } catch (error) {
      console.error('Ошибка при загрузке сохраненных чатов:', error);
    }
  };

  return (
    <div>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>
          <Button
            type="link"
            icon={<LeftOutlined />}
            onClick={() => window.history.back()}
          >
            Назад
          </Button>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="add-account-container">
        <Link to="/account-auth">
          <Button className="add-account-btn">Добавить аккаунт</Button>
        </Link>
        <Button className="saved-chats-btn" onClick={handleSavedChats}>Сохраненные чаты</Button>
      </div>
      <Table dataSource={accounts} columns={columns} className='account-table' />

      <ChatListModal
        visible={isChatListVisible}
        chats={chatsToShow}
        accountId={accountId}
        onCancel={handleCancel}
      />
      <SavedChatsModal
        visible={isSavedChatsVisible}
        chats={savedChats}
        onCancel={handleCancel}
      />

      <Modal
        title="Подтверждение удаления"
        visible={deleteConfirmationVisible}
        onOk={handleDeleteAccount}
        onCancel={handleCancel}
      >
        <p>Вы уверены, что хотите удалить аккаунт?</p>
      </Modal>
    </div>
  );
}

export default AccountList;
