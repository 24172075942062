import React, { useState } from "react";
import PeriodicMailingList from "../PeriodicMailingList";
import RandomMailingList from "../RandomMailingList";
import { Breadcrumb, Button, Radio } from "antd";
import { LeftOutlined } from '@ant-design/icons';

const MailingList = () => {
    const [selectedTable, setSelectedTable] = useState('periodic'); // 'periodic' по умолчанию

    const handleTableChange = (e) => {
        setSelectedTable(e.target.value);
    };

    return (
        <div className="mailing-list-container">
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>
                    <Button
                        type="link"
                        icon={<LeftOutlined />}
                        onClick={() => window.history.back()}
                    >
                        Назад
                    </Button>
                </Breadcrumb.Item>
            </Breadcrumb>

            <div style={{ marginBottom: '16px' }}>
                <Radio.Group value={selectedTable} onChange={handleTableChange} style={{ marginRight: '16px' }}>
                    <Radio.Button value="periodic">Периодические рассылки</Radio.Button>
                    <Radio.Button value="random">Рандомные рассылки</Radio.Button>
                </Radio.Group>
            </div>

            {selectedTable === 'periodic' ? <PeriodicMailingList /> : <RandomMailingList/>}
        </div>
    );
};

export default MailingList;
