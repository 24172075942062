import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Home from './components/Home';
import SendMessage from './components/SendMessage';
import './styles.css';
import TelegramScript from "./components/TelegramScript";
import MailingForm from './components/MailingForm';
import PeriodicMailingList from './components/PeriodicMailingList';
import AccountList from './components/AccountList';
import AccountAuth from './components/AccountAuth';
import RandomMailingForm from './components/RandomMailingForm';
import MailingList from './components/MailingList';

function App() {
    return (
        <div>
            {TelegramScript()}
            <Router>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/mailingform" element={<MailingForm />} />
                    <Route path="/random-mailingform" element={<RandomMailingForm />} />
                    <Route path="/send-message" element={<SendMessage />} />
                    <Route path="/mailing-list" element={<MailingList />} />
                    <Route path="/account-list" element={<AccountList />} />
                    <Route path="/account-auth" element={<AccountAuth />} />
                </Routes>
            </Router>
        </div>
    );
}

export default App;
