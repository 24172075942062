import React, { useState, useEffect } from 'react';
import { Form, Input, Select, Table, Button, Checkbox, Breadcrumb, InputNumber, TimePicker, Row, Col } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import axios from 'axios';
import './index.css';

const { Option } = Select;

const MailingForm = () => {
    const [accounts, setAccounts] = useState([]);
    const [chats, setChats] = useState([]);
    const [selectedChats, setSelectedChats] = useState([]);
    const [form] = Form.useForm();

    useEffect(() => {
        axios.get('/api/telegram/accounts/')
            .then(response => {
                setAccounts(response.data);
            })
            .catch(error => {
                console.error('Error fetching accounts:', error);
            });
    }, []);

    const handleAccountChange = (accountId) => {
        form.resetFields(['chats']);
        const selectedAccount = accounts.find(account => account.id === accountId);
        setChats(selectedAccount ? selectedAccount.chats : []);
        setSelectedChats([]);
    };

    const handleCheckboxChange = (chatId) => {
        const newSelectedChats = selectedChats.includes(chatId)
            ? selectedChats.filter((id) => id !== chatId)
            : [...selectedChats, chatId];

        setSelectedChats(newSelectedChats);
    };

    const handleSelectAllChats = () => {
        const allChatIds = chats.map(chat => chat.id);
        setSelectedChats(allChatIds);
    };

    const onFinish = async (values) => {
        try {
            const selectedChatsData = chats.filter(chat => selectedChats.includes(chat.id));
            values.chats = selectedChatsData;

            // Преобразование времени из объектов moment в строки
            try {
                if (values.sleep_time_start || values.sleep_time_finish) {
                    values.sleep_time_start = values.sleep_time_start.format("HH:mm");
                    values.sleep_time_finish = values.sleep_time_finish.format("HH:mm");
                }
            } catch (error) {
                alert("Оба поля интервала сна должны быть заданы");
                return
            }

            if (values.period > 0) {
                values.period *= 60;
            } else {
                values.period = null;
            }

            const response = await axios.post('/api/telegram/periodic_mailing/', values);
            alert('Рассылка успешно создана!');
        } catch (error) {
            console.error('An unexpected error occurred:', error);

            if (error.response && error.response.status === 400) {
                alert(`Error 400: ${JSON.stringify(error.response.data)}`);
            } else {
                alert(`An unexpected error occurred: ${error}`);
            }
        }
    };

    const columns = [
        { title: 'Название чата', dataIndex: 'name', key: 'name' },
        { title: 'ID чата', dataIndex: 'id', key: 'id' },
        {
            title: 'Выбрать',
            dataIndex: 'id',
            key: 'select',
            render: (id) => (
                <Checkbox
                    onChange={() => handleCheckboxChange(id)}
                    checked={selectedChats.includes(id)}
                />
            ),
        },
    ];

    return (
        <div className="mailing-form-container">
            <Breadcrumb style={{ marginBottom: '16px' }}>
                <Breadcrumb.Item>
                    <Button
                        type="link"
                        icon={<LeftOutlined />}
                        onClick={() => window.history.back()}
                    >
                        Назад
                    </Button>
                </Breadcrumb.Item>
            </Breadcrumb>

            <Form form={form} onFinish={onFinish} layout="vertical">
                <Form.Item label="Имя рассылки" name="name" required>
                    <Input />
                </Form.Item>

                <Form.Item label="Период рассылки (минуты)" name="period" required>
                    <InputNumber min={0.5} />
                </Form.Item>

                <Form.Item label="Выбор аккаунта" name="tg_account" required>
                    <Select onChange={handleAccountChange}>
                        {accounts.map(account => (
                            <Option key={account.id} value={account.id}>
                                {account.name}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item label="Выбор чатов" name="chats" required>
                    <Table dataSource={chats} columns={columns} rowKey="id" pagination={false} />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" onClick={handleSelectAllChats}>
                        Выбрать все чаты
                    </Button>
                </Form.Item>

                <Form.Item label="Интервал сна" >
                    <Row gutter={8}>
                        <Col span={12}>
                            <Form.Item name="sleep_time_start" >
                                <TimePicker format="HH:mm" placeholder="Начало сна" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="sleep_time_finish">
                                <TimePicker format="HH:mm" placeholder="Конец сна" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form.Item>

                <Form.Item label="Текст сообщения" name="text" required>
                    <Input.TextArea rows={4} />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Подтвердить
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default MailingForm;
