import React, { useState, useEffect } from 'react';
import { Modal, Table, Checkbox, Button, message, Tag } from 'antd';
import './index.css';

const AddSavedChatsModal = ({ visible, accountId, chats, onCancel, onConfirm }) => {
  const [selectedChats, setSelectedChats] = useState([]);

  useEffect(() => {
    // Сброс выбранных чатов при открытии модального окна
    setSelectedChats([]);
  }, [visible]);

  const handleCheckboxChange = (chatId) => {
    // Обновление выбранных чатов при изменении состояния чекбокса
    setSelectedChats(prevSelectedChats => {
      if (prevSelectedChats.includes(chatId)) {
        return prevSelectedChats.filter(id => id !== chatId);
      } else {
        return [...prevSelectedChats, chatId];
      }
    });
  };

  const handleConfirm = async () => {
    // Подтверждение выбора чатов и отправка на бэкенд
    if (selectedChats.length === 0) {
      message.warning('Выберите хотя бы один чат');
    } else {
      try {
        const response = await fetch('/api/telegram/post_chats_to_join/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            account_id: accountId,
            chat_ids: selectedChats,
          }),
        });

        if (response.ok) {
          message.success('Чаты успешно добавлены');
          onConfirm(); // Уведомляем родительский компонент о успешном добавлении чатов
        } else {
          const errorData = await response.json();
          message.error(`Ошибка при добавлении чатов: ${errorData.message}`);
        }
      } catch (error) {
        console.error('Ошибка при отправке запроса:', error);
        message.error('Ошибка при добавлении чатов');
      }
    }
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Название',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Тип',
      dataIndex: 'url',
      key: 'url',
    },
    {
      title: 'Валидность',
      dataIndex: 'is_valid',
      key: 'is_valid',
      render: (isValid) => {
        let color = 'yellow';
        let text = 'Еще не прошел валидацию';
        if (isValid === 'valid') {
          color = 'green';
          text = 'Валиден';
        } else if (isValid === 'invalid') {
          color = 'red';
          text = 'Не валиден';
        }
        return (
          <Tag color={color}>
            {text}
          </Tag>
        );
      },
    },
    {
      title: 'Выбрать',
      dataIndex: 'id',
      key: 'checkbox',
      render: (chatId) => (
        <Checkbox onChange={() => handleCheckboxChange(chatId)} checked={selectedChats.includes(chatId)} />
      ),
    },
  ];

  return (
    <Modal
      title="Выберите чаты"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Отмена
        </Button>,  
        <Button key="confirm" type="primary" onClick={handleConfirm}>
          Подтвердить
        </Button>,
      ]}
    >
      <Table dataSource={chats} columns={columns} />
    </Modal>
  );
};

export default AddSavedChatsModal;
