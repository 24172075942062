import React, { useState, useEffect } from 'react';
import { Modal, Table, Tag, Button, message } from 'antd';
import AddChatModal from '../AddChatsModal';
import './index.css';

const SavedChatsModal = ({ visible, chats, onCancel }) => {
  const [selectedChat, setSelectedChat] = useState(null);
  const [confirmDeleteVisible, setConfirmDeleteVisible] = useState(false);
  const [updatedChats, setUpdatedChats] = useState([]);
  const [addChatVisible, setAddChatVisible] = useState(false);

  useEffect(() => {
    setUpdatedChats(chats);
  }, [chats]);

  const handleDelete = async (id) => {
    try {
      setSelectedChat(id);
      setConfirmDeleteVisible(true);
    } catch (error) {
      console.error('Ошибка при удалении чата:', error);
    }
  };

  const confirmDelete = async () => {
    try {
      const response = await fetch(`/api/telegram/saved_chats/${selectedChat}`, { method: 'DELETE' });
      setConfirmDeleteVisible(false);

      if (response.status === 204) {
        message.success('Чат успешно удален');
        const updatedChatsList = chats.filter(chat => chat.id !== selectedChat);
        setUpdatedChats(updatedChatsList);
      } else {
        const errorData = await response.json();
        message.error(`Ошибка при удалении чата: ${errorData.message}`);
      }
    } catch (error) {
      console.error('Ошибка при удалении чата:', error);
      message.error('Ошибка при удалении чата');
    }
  };

  const handleCancelDelete = () => {
    setConfirmDeleteVisible(false);
  };

  const handleAddChat = async (formData) => {
    try {
      const response = await fetch('/api/telegram/saved_chats/  ', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        message.success('Чат успешно добавлен');
        const newChat = await response.json();
        setUpdatedChats([...updatedChats, newChat]);
        setAddChatVisible(false);
      } else {
        const errorData = await response.json();
        message.error(`Ошибка при добавлении чата: ${errorData.message}`);
      }
    } catch (error) {
      console.error('Ошибка при добавлении чата:', error);
      message.error('Ошибка при добавлении чата');
    }
  };

  const handleAddChatCancel = () => {
    setAddChatVisible(false);
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Название',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Тип',
      dataIndex: 'url',
      key: 'url',
    },
    {
      title: 'Валидность',
      dataIndex: 'is_valid',
      key: 'is_valid',
      render: (isValid) => {
        let color = 'yellow';
        let text = 'Еще не прошел валидацию';
        if (isValid === 'valid') {
          color = 'green';
          text = 'Валиден';
        } else if (isValid === 'invalid') {
          color = 'red';
          text = 'Не валиден';
        }
        return (
          <Tag color={color}>
            {text}
          </Tag>
        );
      },
    },
    {
      title: 'Действия',
      key: 'actions',
      render: (text, record) => (
        <Button type="danger" onClick={() => handleDelete(record.id)}>Удалить</Button>
      ),
    },
  ];

  return (
    <div>
      <Modal
        title="Подтверждение удаления"
        visible={confirmDeleteVisible}
        onOk={confirmDelete}
        onCancel={handleCancelDelete}
      >
        <p>Вы уверены, что хотите удалить этот чат?</p>
      </Modal>

      <Modal
        title="Список сохраненных чатов"
        visible={visible}
        onCancel={onCancel}
        footer={[
          <Button key="addChat" type="primary" onClick={() => setAddChatVisible(true)}>
            Добавить чат
          </Button>,
        ]}
      >
        <Table dataSource={updatedChats} columns={columns} />
      </Modal>

      {/* Модальное окно для добавления чата */}
      <AddChatModal
        visible={addChatVisible}
        onCancel={() => setAddChatVisible(false)}
        onAdd={handleAddChat}
      />
    </div>
  );
};

export default SavedChatsModal;
