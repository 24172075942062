import React, { useState, useEffect } from 'react';
import { Form, Input, Button, notification, Modal, Breadcrumb } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './index.css';

const AccountAuth = () => {
  const [form] = Form.useForm();
  const [resultId, setResultId] = useState(null);
  const [timer, setTimer] = useState(120);
  const [visible, setVisible] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    let countdown;

    if (timer > 0 && visible) {
      countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => clearInterval(countdown);
  }, [timer, visible]);

  const startAuthentication = async (values) => {
    try {
      const response = await axios.post('/api/telegram/start_auth/', {
        name: values.name,
        phone: values.phone.replace(/[^0-9]/g, ''),
      });

      const { result_id } = response.data;
      setResultId(result_id);

      notification.success({
        message: 'Аутентификация успешно начата',
      });

      setVisible(true);
    } catch (error) {
      console.error('Ошибка при начале аутентификации:', error);
      notification.error({
        message: 'Ошибка при начале аутентификации',
        description: `Ошибка запроса: ${JSON.stringify(error.response.data)}`,
      });
    }
  };

  const finishAuthentication = async (values) => {
    try {
      await axios.post('/api/telegram/finish_auth/', {
        phone: values.phone.replace(/[^0-9]/g, ''),
        code: values.code,
        result_id: resultId,
      });

      notification.success({
        message: 'Аутентификация успешно завершена',
      });
      
      setVisible(false);
      setAuthenticated(true);
    } catch (error) {
      console.error('Ошибка при завершении аутентификации:', error);
      setVisible(false);
      notification.error({
        message: 'Ошибка при подтверждении кода',
        description: `повторите попытку снова (вводить нужно новый код)`,
      });
    }
  };

  // Render Redirect component if authenticated is true
  if (authenticated) {
    navigate('/account-list');
    return null; // Return null to prevent rendering anything while navigating
  }

  return (
    <div>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>
          <Button
            type="link"
            icon={<LeftOutlined />}
            onClick={() => window.history.back()}
          >
            Назад
          </Button>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="account-auth-container">
        <Form form={form} onFinish={startAuthentication} layout="vertical">
          <Form.Item
            label="Имя"
            name="name"
            rules={[{ required: true, message: 'Пожалуйста, введите ваше имя' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Номер телефона"
            name="phone"
            rules={[
              { required: true, message: 'Пожалуйста, введите ваш номер телефона' },
              {
                pattern: /^[0-9]{11}$/,
                message: 'Номер телефона должен содержать 11 цифр',
              },
            ]}
          >
            <Input placeholder="89123456789" maxLength={11} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Начать аутентификацию
            </Button>
          </Form.Item>
        </Form>

        <Modal
          title="Введите код подтверждения"
          visible={visible}
          onCancel={() => setVisible(false)}
          footer={null}
        >
          <Form form={form} onFinish={finishAuthentication} layout="vertical">
            <Form.Item
              label={`Код подтверждения (Осталось времени: ${timer}s)`}
              name="code"
              rules={[
                { required: true, message: 'Пожалуйста, введите код подтверждения' },
                { pattern: /^\d{5}$/, message: 'Код должен состоять из 5 цифр' },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Подтвердить
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </div>
  );
};

export default AccountAuth;
