import React, { useState } from 'react';
import { Modal, Form, Input, Button, message } from 'antd';

const AddChatModal = ({ visible, onCancel, onAdd }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleAddChat = async () => {
    try {
      setLoading(true);
      const formData = await form.validateFields();
      onAdd(formData);
      form.resetFields();
      setLoading(false);
    } catch (error) {
      console.error('Ошибка при добавлении чата:', error);
      setLoading(false);
      message.error('Ошибка при добавлении чата');
    }
  };

  return (
    <Modal
      title="Добавить чат"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Отмена
        </Button>,
        <Button key="add" type="primary" loading={loading} onClick={handleAddChat}>
          Добавить
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical" name="addChatForm">
        <Form.Item
          name="url"
          label="URL"
          rules={[
            {
              required: true,
              message: 'Пожалуйста, введите URL чата',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="title"
          label="Название"
          rules={[
            {
              required: true,
              message: 'Пожалуйста, введите название чата',
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddChatModal;
