// Navbar.jsx

import React from 'react';
import './index.css';

export default function Navbar() {
  return (
    <nav className="navigation">
      <span className="logo">РассылкаБот</span>
    </nav>
  );
}
