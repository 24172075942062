import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import './../styles.css';

function SendMessage() {
    const [recipients, setRecipients] = useState([]);
    const [selectedRecipients, setSelectedRecipients] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [alerts, setAlerts] = useState([]); // Состояние для хранения алертов

    useEffect(() => {
        setIsLoading(true);
        axios.get('/api/telegram/get_dialogs')
            .then(response => {
                if (response.data.status === 'ok') {
                    setRecipients(response.data.data);
                    setIsLoading(false);
                }
            })
            .catch(error => {
                console.error('Ошибка при получении данных', error);
                setIsLoading(false);
            });
    }, []);

    const handleRecipientSelection = (recipientId) => {
        if (selectedRecipients.includes(recipientId)) {
            setSelectedRecipients(selectedRecipients.filter(id => id !== recipientId));
        } else {
            setSelectedRecipients([...selectedRecipients, recipientId]);
        }
        setSelectAll(false);
    };

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedRecipients([]);
        } else {
            const allRecipientIds = recipients.map(recipient => recipient.id);
            setSelectedRecipients(allRecipientIds);
        }
        setSelectAll(!selectAll);
    };

    const handleSendMessage = () => {
        setIsButtonDisabled(true); // Отключение кнопки отправки
        const data = {
            chats: selectedRecipients,
            text: message,
        };
        axios.post('/api/telegram/send_messages/', data)
            .then(response => {
                console.log('Сообщение успешно отправлено');
                addAlert('Сообщение успешно отправлено');
                setTimeout(() => {
                    setIsButtonDisabled(false); // Включение кнопки отправки после 1 секунды
                }, 1000);
            })
            .catch(error => {
                console.error('Ошибка при отправке сообщения', error);
                setIsButtonDisabled(false); // Включение кнопки отправки в случае ошибки
            });
    };

    const addAlert = (message) => {
        setAlerts((prevAlerts) => [message, ...prevAlerts]);
    };

    const removeAlert = () => {
        setAlerts((prevAlerts) => prevAlerts.slice(0, prevAlerts.length - 1));
    };

    return (
        <div>
            <header>
                <h1>Отправить сообщения</h1>
                <Link to="/" className="back-button">Назад</Link>
            </header>
            {isLoading ? (
                <div className="loader">Загрузка...</div>
            ) : (
                <div>
                    <table>
                        <thead>
                        <tr>
                            <th>Выбрать</th>
                            <th>Имя</th>
                            <th>Тип</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>
                                <input
                                    type="checkbox"
                                    checked={selectAll}
                                    onChange={handleSelectAll}
                                />
                            </td>
                            <td>Выбрать все</td>
                            <td></td>
                        </tr>
                        {recipients.map(recipient => (
                            <tr key={recipient.id}>
                                <td>
                                    <input
                                        type="checkbox"
                                        checked={selectedRecipients.includes(recipient.id)}
                                        onChange={() => handleRecipientSelection(recipient.id)}
                                    />
                                </td>
                                <td>{recipient.name}</td>
                                <td>{recipient.type}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    <div>
            <textarea
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Введите сообщение"
                className="text-input"
            />
                        <button
                            onClick={handleSendMessage}
                            className="send-button"
                            disabled={isButtonDisabled}
                        >
                            Отправить
                        </button>
                    </div>
                    {/* Отображение алертов */}
                    <div className="alerts-container">
                        {alerts.map((alert, index) => (
                            <div
                                key={index}
                                className={`custom-alert ${index === 0 ? '' : 'custom-alert-closed'}`}
                                onClick={removeAlert}
                            >
                                {alert}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
}

export default SendMessage;
