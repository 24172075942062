import React, { useState, useEffect } from 'react';
import { Modal, Table, Button } from 'antd';
import './index.css';
import AddSavedChatsModal from '../AddSavedChatsModa';

const ChatListModal = ({ visible, chats, accountId,  onCancel }) => {
  const [addSavedChatsModalVisible, setAddSavedChatsModalVisible] = useState(false);
  const [savedChats, setSavedChats] = useState([]);
  

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Тип',
      dataIndex: 'type',
      key: 'type',
    },
  ];

  const handleAddFromSavedChats = () => {
    // Открыть модальное окно для добавления сохраненных чатов
    setAddSavedChatsModalVisible(true);
  };

  const handleAddSavedChatsCancel = () => {
    // Закрыть модальное окно для добавления сохраненных чатов
    setAddSavedChatsModalVisible(false);
  };

  useEffect(() => {
    // Получение сохраненных чатов при открытии модального окна
    const fetchSavedChats = async () => {
      try {
        const response = await fetch('/api/telegram/saved_chats/');
        const data = await response.json();
        setSavedChats(data);
      } catch (error) {
        console.error('Ошибка при загрузке сохраненных чатов:', error);
      }
    };

    if (addSavedChatsModalVisible) {
      fetchSavedChats();
    }
  }, [addSavedChatsModalVisible]);

  return (
    <Modal
      title="Список чатов"
      visible={visible}
      onCancel={onCancel}
      footer={null}
    >
      <Button key="add-btn" onClick={handleAddFromSavedChats}>Добавить из сохраненных</Button>
      <Table dataSource={chats} columns={columns} />
      
      <AddSavedChatsModal
        visible={addSavedChatsModalVisible}
        chats={savedChats}
        accountId={accountId}
        onCancel={handleAddSavedChatsCancel}
        onConfirm={()=>{setAddSavedChatsModalVisible(false)}}
      />
    </Modal>
  );
};

export default ChatListModal;
