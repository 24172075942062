import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from './Navbar';
import './../styles.css';


function Home() {
  const buttonStyle = {
    display: 'block',
    width: '300px',
    padding: '13px',
    margin: '14px auto',
    textAlign: 'center',
    textDecoration: 'none',
    backgroundColor: '#007bff',
    color: 'white',
    borderRadius: '5px',
    cursor: 'pointer',
  };

  return (
    <div>
      <Navbar />
      <div>
        {/* <Link to="/send-message" className={'button'} style={buttonStyle}>
          Отправить сообщения
        </Link> */}
        <Link to="/mailingform" style={buttonStyle}>
          Настроить новую периодическую рассылку
        </Link>
        <Link to="/random-mailingform" style={buttonStyle}>
          Настроить новую рандомную рассылку
        </Link>
        <Link to="/mailing-list" style={buttonStyle}>
          Текущие рассылки
        </Link>
        <Link to="/account-list" style={buttonStyle}>
          Аккаунты \ Чаты
        </Link>
        <a  href="/admin/"  style={buttonStyle}>
          Перейти на админ-панель
        </a>
        {/* <Link to="/account-list" style={buttonStyle}>
          Аккаунты 
        </Link> */}
      </div>
    </div>
  );
}

export default Home;
