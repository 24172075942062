import React, { useState, useEffect } from 'react';
import { Form, Input, Select, Table, Button, Checkbox, Breadcrumb, TimePicker, Row, Col } from 'antd';
import { LeftOutlined, PlusOutlined, CloseOutlined } from '@ant-design/icons';
import axios from 'axios';
import moment from 'moment';

const { Option } = Select;

const EditRandomMailing = ({ mailingId }) => {
    const [accounts, setAccounts] = useState([]);
    const [chats, setChats] = useState([]);
    const [selectedChats, setSelectedChats] = useState([]);
    const [textFields, setTextFields] = useState({ 0: "" });

    const [form] = Form.useForm();
    useEffect(() => {
        axios.get(`/api/telegram/random_mailing/${mailingId}/`)
            .then(response => {
                const periodRange = [
                    response.data.start_period_boundary
                        ? moment(response.data.start_period_boundary, 'HH:mm:ss')
                        : null,
                    response.data.finish_period_boundary
                        ? moment(response.data.finish_period_boundary, 'HH:mm:ss')
                        : null,
                ];

                form.setFieldsValue({
                    name: response.data.name,
                    periodRange: periodRange,
                    tg_account: response.data.tg_account,
                    chats: response.data.chats.map(chat => chat.id),
                    sleep_time_start: response.data.sleep_time_start
                        ? moment(response.data.sleep_time_start, 'HH:mm:ss')
                        : null,
                    sleep_time_finish: response.data.sleep_time_finish
                        ? moment(response.data.sleep_time_finish, 'HH:mm:ss')
                        : null,
                    text: response.data.text,
                });

                setChats(response.data.chats);
                setSelectedChats(response.data.chats.map(chat => chat.id));
                setTextFields(response.data.text);
            })
            .catch(error => {
                console.error('Error fetching random mailing data:', error);
            });

        axios.get('/api/telegram/accounts/')
            .then(response => {
                setAccounts(response.data);
            })
            .catch(error => {
                console.error('Error fetching accounts:', error);
            });
    }, [form, mailingId]);

    const handleTextFieldChange = (index, value) => {
        const newTextFieldValues = { ...textFields }; // Создаем новый объект
        newTextFieldValues[index] = value;
        setTextFields(newTextFieldValues);
    };

    const handleAccountChange = (accountId) => {
        form.resetFields(['chats']);
        const selectedAccount = accounts.find(account => account.id === accountId);
        setChats(selectedAccount ? selectedAccount.chats : []);
        setSelectedChats([]);
    };

    const handleCheckboxChange = (chatId) => {
        const newSelectedChats = selectedChats.includes(chatId)
            ? selectedChats.filter((id) => id !== chatId)
            : [...selectedChats, chatId];

        setSelectedChats(newSelectedChats);
    };

    const handleAddTextField = () => {
        setTextFields([...textFields, ""]);
        form.setFieldsValue({
            text: [...textFields, ""],
        });
    };

    const handleRemoveTextField = (index) => {
        const newTextFieldValues = [...textFields];
        newTextFieldValues.splice(index, 1);
        setTextFields(newTextFieldValues);
        form.setFieldsValue({
            text: newTextFieldValues,
        });
    };

    const onFinish = async (values) => {
        try {
            const selectedChatsData = chats.filter(chat => selectedChats.includes(chat.id));
            values.chats = selectedChatsData;
            values.text = Object.values(textFields);

            try {
                if (values.periodRange && values.periodRange.length === 2) {
                    values.start_period_boundary = values.periodRange[0].format("HH:mm:00");
                    values.finish_period_boundary = values.periodRange[1].format("HH:mm:00");
                }

            } catch (error) {
                alert("Оба поля интервала периода рассылки должны быть заданы");
                return;
            }

            try {
                if (values.sleep_time_start || values.sleep_time_finish) {
                    values.sleep_time_start = values.sleep_time_start.format("HH:mm");
                    values.sleep_time_finish = values.sleep_time_finish.format("HH:mm");
                }
            } catch (error) {
                alert("Оба поля интервала сна должны быть заданы или не задан ни один");
                return
            }


            await axios.put(`/api/telegram/random_mailing/${mailingId}/`, values);
            alert('Рассылка успешно обновлена');
        } catch (error) {
            console.error('An unexpected error occurred:', error);

            if (error.response && error.response.status === 400) {
                alert(`Error 400: ${JSON.stringify(error.response.data)}`);
            } else {
                alert(`An unexpected error occurred: ${error}`);
            }
        }
    };

    const columns = [
        { title: 'Название чата', dataIndex: 'name', key: 'name' },
        { title: 'ID чата', dataIndex: 'id', key: 'id' },
        {
            title: 'Выбрать',
            dataIndex: 'id',
            key: 'select',
            render: (id) => (
                <Checkbox
                    onChange={() => handleCheckboxChange(id)}
                    checked={selectedChats.includes(id)}
                />
            ),
        },
    ];
    const handleSelectAllChats = () => {
        const allChatIds = chats.map(chat => chat.id);
        setSelectedChats(allChatIds);
        form.setFieldsValue({
            chats: allChatIds,
        });
    };

    return (
        <div className="mailing-form-container">
            <Breadcrumb style={{ marginBottom: '16px' }}>
                <Breadcrumb.Item>
                    <Button
                        type="link"
                        icon={<LeftOutlined />}
                        onClick={() => window.history.back()}
                    >
                        Назад
                    </Button>
                </Breadcrumb.Item>
            </Breadcrumb>

            <Form form={form} onFinish={onFinish} layout="vertical">
                <Form.Item label="Имя рассылки" name="name" required>
                    <Input />
                </Form.Item>

                <Form.Item label="Границы периода, с которым будут отправляться сообщения" required>
                    <Form.Item name="periodRange" noStyle>
                        <TimePicker.RangePicker
                            format="HH:mm"
                            placeholder={['Начало', 'Конец']}
                            style={{ width: '100%' }}
                        />
                    </Form.Item>
                </Form.Item>

                <Form.Item label="Выбор аккаунта" name="tg_account" required>
                    <Select onChange={handleAccountChange}>
                        {accounts.map(account => (
                            <Option key={account.id} value={account.id}>
                                {account.name}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item label="Выбор чатов" name="chats" required>
                    <Table dataSource={chats} columns={columns} rowKey="id" pagination={false} />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" onClick={handleSelectAllChats}>
                        Выбрать все чаты
                    </Button>
                </Form.Item>

                <Form.Item label="Интервал сна">
                    <Row gutter={8}>
                        <Col span={12}>
                            <Form.Item name="sleep_time_start">
                                <TimePicker format="HH:mm" placeholder="Начало сна" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="sleep_time_finish">
                                <TimePicker format="HH:mm" placeholder="Конец сна" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form.Item>

                <Form.Item label="Фразы" name="text" required>
                    {Object.keys(textFields).map((index) => (
                        <Row key={index} gutter={8}>
                            <Col span={18}>
                                <Form.Item>
                                    <Input.TextArea
                                        value={textFields[index]}
                                        onChange={(e) => handleTextFieldChange(index, e.target.value)}
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6} style={{ color: '#F08080', marginTop: '10px' }}>
                                <Button
                                    type="link"
                                    icon={<CloseOutlined style={{ color: '#F08080', marginTop: '10px' }} />}
                                    onClick={() => handleRemoveTextField(index)}
                                />
                            </Col>
                        </Row>
                    ))}
                    <Button type="link" icon={<PlusOutlined />} onClick={handleAddTextField}>
                        Добавить фразу
                    </Button>
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Подтвердить
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default EditRandomMailing;
