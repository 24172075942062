import React, { useEffect, useState } from 'react';
import { Table, Typography, Button, message, Modal, Switch } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import axios from 'axios';
import EditRandomMailing from '../EditRandomMailing';
// import './index.css';

const { Text } = Typography;

const RandomMailingList = () => {
    const [mailings, setMailings] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const [editingMailing, setEditingMailing] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [deleteConfirmationVisible, setDeleteConfirmationVisible] = useState(false);
    const [deletingMailing, setDeletingMailing] = useState(null);

    useEffect(() => {
        axios.get('/api/telegram/random_mailing/')
            .then(response => {
                setMailings(response.data);
            })
            .catch(error => {
                console.error('Error fetching random mailings:', error);
            });

        axios.get('/api/telegram/accounts/')
            .then(response => {
                setAccounts(response.data);
            })
            .catch(error => {
                console.error('Error fetching accounts:', error);
            });
    }, []);

    const handleEdit = (record) => {
        setEditingMailing(record);
        setIsModalVisible(true);
    };

    const handleDelete = (record) => {
        setDeletingMailing(record);
        setDeleteConfirmationVisible(true);
    };

    const confirmDelete = async () => {
        try {
            if (deletingMailing && deletingMailing.id) {
                await axios.delete(`/api/telegram/random_mailing/${deletingMailing.id}/`);
                setMailings(mailings.filter(item => item.id !== deletingMailing.id));
                message.success('Рассылка успешно удалена');
            } else {
                message.error('Невозможно удалить рассылку. ID не определен.');
            }
        } catch (error) {
            console.error('Error deleting mailing:', error);
            message.error('Ошибка при удалении рассылки');
        } finally {
            setDeleteConfirmationVisible(false);
            setDeletingMailing(null);
        }
    };
    
    const cancelDelete = () => {
        setDeleteConfirmationVisible(false);
        setDeletingMailing(null);
    };
    
    const handleToggleActive = async (mailingId, checked) => {
        try {
            const mailing = mailings.find((m) => m.id === mailingId);
    
            if (mailing) {
                await axios.put(`/api/telegram/random_mailing/${mailingId}/`, {
                    is_active: checked,
                    name: mailing.name,
                    text: mailing.text,
                    start_period_boundary: mailing.start_period_boundary,
                    finish_period_boundary: mailing.finish_period_boundary,
                    tg_account: mailing.tg_account,
                    chats: mailing.chats,
                });
    
                setMailings((prevMailings) =>
                    prevMailings.map((m) =>
                        m.id === mailingId ? { ...m, is_active: checked } : m
                    )
                );
    
                message.success(`Рассылка ${checked ? 'включена' : 'выключена'}`);
            } else {
                message.error('Рассылка не найдена.');
            }
        } catch (error) {
            console.error('Error toggling mailing active status:', error);
            message.error('Ошибка при изменении статуса рассылки');
        }
    };

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Название',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Период',
            dataIndex: 'period',
            key: 'period',
            render: (text, record) => (
                <span>{`${record.start_period_boundary} -    ${record.finish_period_boundary}`}</span>
            ),
        },
    
        {
            title: 'Активна',
            dataIndex: 'is_active',
            key: 'is_active',
            render: (text, record) => (
                <Switch
                    checked={text}
                    onChange={(checked) => handleToggleActive(record.id, checked)}
                />
            ),
        },
        {
            title: 'Текст',
            dataIndex: 'text',
            key: 'text',
            render: (text) => (
                <Text ellipsis>{text.length > 7 ? `${text.slice(0, 7)}...` : text}</Text>
            ),
        },
        {
            title: 'Телеграм аккаунт',
            dataIndex: 'tg_account',
            key: 'tg_account',
            render: (tgAccountId) => {
                const account = accounts.find(account => account.id === tgAccountId);
                return account ? account.name : 'N/A';
            },
        },
        {
            title: 'Действия',
            key: 'actions',
            render: (text, record) => (
                <div style={{ display: 'flex', gap: '8px' }}>
                    <Button
                        type="primary"
                        icon={<EditOutlined />}
                        size="middle"
                        onClick={() => handleEdit(record)}
                    >
                        Редактировать
                    </Button>
                    <Button
                        type="danger"
                        icon={<DeleteOutlined />}
                        size="middle"
                        onClick={() => handleDelete(record)}
                    >
                        Удалить
                    </Button>
                </div>
            ),
        },
    ];

    const handleModalCancel = () => {
        setIsModalVisible(false);
        setEditingMailing(null);
    };

    return (
        <div className="mailing-list-container">
            <Table
                dataSource={mailings}
                columns={columns}
                rowKey="id"
                pagination={{ pageSize: 10 }}
            />

            <Modal
                title="Подтвердите удаление"
                visible={deleteConfirmationVisible}
                onOk={confirmDelete}
                onCancel={cancelDelete}
                okText="Да"
                cancelText="Нет"
            >
                <p>Вы уверены, что хотите удалить эту запись?</p>
            </Modal>

            <Modal
                title="Редактировать рассылку"
                open={isModalVisible}
                onCancel={handleModalCancel}
                footer={null}
                maskClosable={false}
            >
                {editingMailing && <EditRandomMailing mailingId={editingMailing.id} />}
            </Modal>
        </div>
    );
};

export default RandomMailingList;
